// Generated by Framer (f4b44ab)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as localizedValues from "./hA2pP5b1z-0.js";

const cycleOrder = ["pABbjTtsG", "PVQnU1_wF", "PLPqAdFsN", "xbWvAQEt3"];

const serializationHash = "framer-TaPxk"

const variantClassNames = {pABbjTtsG: "framer-v-1ir3kyg", PLPqAdFsN: "framer-v-rfuctt", PVQnU1_wF: "framer-v-18u9jcp", xbWvAQEt3: "framer-v-1akirr9"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const valuesByLocaleId = {VMMHDo1EA: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Navigation 1": "pABbjTtsG", "Navigation 2": "PVQnU1_wF", "Navigation 3": "PLPqAdFsN", "Navigation 4": "xbWvAQEt3"}

const getProps = ({height, id, tap, width, ...props}) => { return {...props, eRctTDLt5: tap ?? props.eRctTDLt5, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "pABbjTtsG"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;tap?: any; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, eRctTDLt5, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "pABbjTtsG", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTaptg053b = activeVariantCallback(async (...args) => {
if (eRctTDLt5) {
const res = await eRctTDLt5(...args);
if (res === false) return false;
}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-TaPxk", ...sharedStyleClassNames, classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-1ir3kyg", className)} data-framer-name={"Navigation 1"} data-highlight layoutDependency={layoutDependency} layoutId={"pABbjTtsG"} onTap={onTaptg053b} ref={ref ?? ref1} style={{backgroundColor: "rgb(255, 255, 255)", borderBottomLeftRadius: 12, borderBottomRightRadius: 12, borderTopLeftRadius: 12, borderTopRightRadius: 12, boxShadow: "0px 5px 15px 2px rgba(170, 170, 170, 0.2)", ...style}} {...addPropertyOverrides({PLPqAdFsN: {"data-framer-name": "Navigation 3"}, PVQnU1_wF: {"data-framer-name": "Navigation 2"}, xbWvAQEt3: {"data-framer-name": "Navigation 4"}}, baseVariant, gestureVariant)}><motion.div className={"framer-16tsxda"} data-framer-name={"Nav Row"} layoutDependency={layoutDependency} layoutId={"r3fjDRm0h"}/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-TaPxk [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-TaPxk .framer-4vwe2 { display: block; }", ".framer-TaPxk .framer-1ir3kyg { align-content: start; align-items: start; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: flex-start; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 660px; will-change: var(--framer-will-change-override, transform); }", ".framer-TaPxk .framer-16tsxda { align-content: start; align-items: start; display: flex; flex: none; flex-direction: column; flex-wrap: nowrap; gap: 0px; height: min-content; justify-content: center; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: min-content; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-TaPxk .framer-1ir3kyg, .framer-TaPxk .framer-16tsxda { gap: 0px; } .framer-TaPxk .framer-1ir3kyg > * { margin: 0px; margin-left: calc(0px / 2); margin-right: calc(0px / 2); } .framer-TaPxk .framer-1ir3kyg > :first-child { margin-left: 0px; } .framer-TaPxk .framer-1ir3kyg > :last-child { margin-right: 0px; } .framer-TaPxk .framer-16tsxda > * { margin: 0px; margin-bottom: calc(0px / 2); margin-top: calc(0px / 2); } .framer-TaPxk .framer-16tsxda > :first-child { margin-top: 0px; } .framer-TaPxk .framer-16tsxda > :last-child { margin-bottom: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 446
 * @framerIntrinsicWidth 660
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]},"PVQnU1_wF":{"layout":["fixed","auto"]},"PLPqAdFsN":{"layout":["fixed","auto"]},"xbWvAQEt3":{"layout":["fixed","auto"]}}}
 * @framerVariables {"eRctTDLt5":"tap"}
 * @framerImmutableVariables true
 */
const FramerhA2pP5b1z: React.ComponentType<Props> = withCSS(Component, css, "framer-TaPxk") as typeof Component;
export default FramerhA2pP5b1z;

FramerhA2pP5b1z.displayName = "1 - Toggle box";

FramerhA2pP5b1z.defaultProps = {height: 446, width: 660};

addPropertyControls(FramerhA2pP5b1z, {variant: {options: ["pABbjTtsG", "PVQnU1_wF", "PLPqAdFsN", "xbWvAQEt3"], optionTitles: ["Navigation 1", "Navigation 2", "Navigation 3", "Navigation 4"], title: "Variant", type: ControlType.Enum}, eRctTDLt5: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(FramerhA2pP5b1z, [])